import * as yup from 'yup';

export const PhonePrefixRegex =
    /(420|43|49|45|34|30|353|47|48|421|216|381|90|44|46|31|33|39|36|968|212|370|63|852|351|81|52|41|57|372|40|39|359|381|77|380)/;

export const PhoneValidationSchemaFields = {
    // /(420|43|49|45|34|30|353|47|48|421|216|381|90|44|46|31|33|39|36|968|212)/
    phonePrefix: yup
        .string()
        .test(
            'is_allowed_prefix',
            'errors.is_not_allowed_prefix',
            (value, testContext) => {
                const number = testContext.parent.phoneNumber;

                let line;

                if (!testContext.options?.context?.isForm) {
                    //access path for tested field and transform to row number for table
                    //not neccessary for forms where error message is displayed nex to the field (test call field now)
                    const row = [...testContext.path.matchAll(/\[(\d+)\]/g)];
                    line = parseInt(row[0][1]) + 2;
                }

                // as of now prefixes are already matched (or not) elsewhere, but error message is assigned here when the prefix is undefined
                // !testContext.options?.context?.regexAllowedPrefixes.test(value)
                if (
                    !value &&
                    //do not send error here if the whole number is missing
                    number
                ) {
                    //create custom error
                    return testContext.createError({
                        message: testContext.options?.context?.isForm
                            ? 'errors.does_not_match_prefix'
                            : {
                                  key: 'errors.does_not_match_prefix_line',
                                  values: { phone: number, line: line },
                              },
                    });
                } else {
                    return true;
                }
            },
        ),

    phoneNumber: yup
        .string()
        .test(
            'is_allowed_number',
            'errors.is_not_allowed_number',
            (value, testContext) => {
                // need to indicate position in nested scheme - line of record (index in array)
                // only when it is not in form and error message can not be displayed directly nex to the field (context - isForm parameter)
                const context = testContext.options?.context;
                const prefix = testContext.parent?.phonePrefix;

                let line: number | undefined;

                if (!context?.isForm) {
                    //access path for tested field and transform to row number for table
                    const row = [...testContext.path.matchAll(/\[(\d+)\]/g)];
                    line = parseInt(row[0][1]) + 2;
                }

                if (!value) {
                    if (context?.isAttemptingSubmit) {
                        return testContext.createError({
                            message: context?.isForm
                                ? 'errors.missing_phone_number'
                                : {
                                      key: 'errors.missing_phone_number_line',
                                      values: {
                                          line: line,
                                      },
                                  },
                        });
                    } else {
                        return true;
                    }
                } else {
                    if (!/^\d+$/.test(value)) {
                        return testContext.createError({
                            message: context?.isForm
                                ? 'numbers_only'
                                : {
                                      key: 'errors.phone_number_is_not_number_line',
                                      values: {
                                          phone: `${
                                              prefix ? prefix : ''
                                          }${value}`,
                                          line: line,
                                      },
                                  },
                        });
                    } else {
                        if (!context?.regexAllowedPrefixes.test(prefix)) {
                            // not test in case prefix is not one of recognized prefixes
                            return false;
                        } else {
                            switch (prefix) {
                                //CZ
                                case '420':
                                    return validateNumberLengthAndValue(
                                        testContext,
                                        value,
                                        9,
                                        9,
                                        /^(79260|84[012789]|90[069]|91004)\d*$/,
                                        line,
                                    );

                                //AT
                                case '43':
                                    return validateNumberLengthAndValue(
                                        testContext,
                                        value,
                                        15,
                                        undefined,
                                        undefined,
                                        line,
                                    );

                                //DE
                                case '49':
                                    return validateNumberLengthAndValue(
                                        testContext,
                                        value,
                                        15,
                                        undefined,
                                        /^(11[56]|18)\d*$/,
                                        line,
                                    );

                                //DK
                                case '45':
                                    return validateNumberLengthAndValue(
                                        testContext,
                                        value,
                                        15,
                                        undefined,
                                        /^(80[2-9])\d*$/,
                                        line,
                                    );

                                //ES
                                case '34':
                                    return validateNumberLengthAndValue(
                                        testContext,
                                        value,
                                        15,
                                        undefined,
                                        /^(51[01345679]|512[0-9]|51270[023456789]|5127[1-9]|51820|51844[123]|70|8007|80[367]|902)\d*$/,
                                        line,
                                    );

                                //FR
                                case '33':
                                    return validateNumberLengthAndValue(
                                        testContext,
                                        value,
                                        15,
                                        undefined,
                                        undefined,
                                        line,
                                    );

                                //GR //TODO GR - missig destinations table
                                case '30':
                                    return validateNumberLengthAndValue(
                                        testContext,
                                        value,
                                        15,
                                        undefined,
                                        undefined,
                                        line,
                                    );

                                //HU
                                case '36':
                                    return validateNumberLengthAndValue(
                                        testContext,
                                        value,
                                        15,
                                        undefined,
                                        /^212(354|291)\d*$/,
                                        line,
                                    );

                                //IE //TODO IE - missig destinations table
                                case '353':
                                    return validateNumberLengthAndValue(
                                        testContext,
                                        value,
                                        15,
                                        undefined,
                                        undefined,
                                        line,
                                    );

                                //IT
                                case '39':
                                    return validateNumberLengthAndValue(
                                        testContext,
                                        value,
                                        15,
                                        undefined,
                                        undefined,
                                        line,
                                    );

                                //MA
                                case '212': {
                                    const regexNotPossibleNumberMa = new RegExp(
                                        [
                                            '^(61(0609091|1085208|6696921|8801105)',
                                            '61194(0125|3784|4524|5480|7317|859)',
                                            '624(255143|26032|916201)',
                                            '628333506',
                                            '637011951',
                                            '648(080842|663401|811435|812825|817000|818213)',
                                            '650034277',
                                            '661(162412|218509|665274)',
                                            '670(02143|087881|400271|400275|400277)',
                                            '671122(225|269|594)',
                                            '67(2002887|7164002|8211253)',
                                            '69228',
                                            '761051(465|716|871)',
                                            '762(712315|822670)',
                                            '76288(0250|0282|0384|6664)',
                                            '8920)\\d*$',
                                        ].join('|'),
                                    );

                                    return validateNumberLengthAndValue(
                                        testContext,
                                        value,
                                        15,
                                        undefined,
                                        regexNotPossibleNumberMa,
                                        line,
                                    );
                                }

                                //NL
                                case '31': {
                                    const regexNotPossibleNumberNl = new RegExp(
                                        [
                                            '^(68995',
                                            '84',
                                            '970105',
                                            '202626905',
                                            '20807',
                                            '208080102',
                                            '242002',
                                            '382002',
                                            '660',
                                            '67([0-2]|[4-5])',
                                            '673([0-4]|[6-9])',
                                            '6735[3-9]',
                                            '6760',
                                            '67[7-9]',
                                            '702003',
                                            '85',
                                            '87',
                                            '90)\\d*$',
                                        ].join('|'),
                                    );

                                    return validateNumberLengthAndValue(
                                        testContext,
                                        value,
                                        15,
                                        undefined,
                                        regexNotPossibleNumberNl,
                                        line,
                                    );
                                }

                                //NO
                                case '47':
                                    return validateNumberLengthAndValue(
                                        testContext,
                                        value,
                                        15,
                                        undefined,
                                        /^(0[2-9]|188[12]|81[0-5]|85|880)\d*$/,
                                        line,
                                    );

                                //OM
                                case '968':
                                    return validateNumberLengthAndValue(
                                        testContext,
                                        value,
                                        15,
                                        undefined,
                                        undefined,
                                        line,
                                    );

                                //PL
                                case '48': {
                                    const regexNotPossibleNumberPl = new RegExp(
                                        [
                                            '^(12(3950270|3961|6880)',
                                            '221(132|138|180|889|9888|9889)|22(3501|7651|7655)|2574090',
                                            '324501|333335',
                                            '4591|45920([0-8]|9[0-6])|45950([0-8]|9[0-7])',
                                            '528809|566592|57942([0-8]|9[0-7])|57946|57948([0-8]|9[0-8])|57953([0-8]|9[0-7])|58(5359|6702)',
                                            '683004',
                                            '70000(0|1)|71(7079|8626)|73910|73993([0-8]|9[0-4])|7(74843|80206|80208)',
                                            '880|8(14503|57334|58741)',
                                            '9(18820|43437))\\d*$',
                                        ].join('|'),
                                    );

                                    return validateNumberLengthAndValue(
                                        testContext,
                                        value,
                                        15,
                                        undefined,
                                        regexNotPossibleNumberPl,
                                        line,
                                    );
                                }

                                //RS
                                case '381':
                                    return validateNumberLengthAndValue(
                                        testContext,
                                        value,
                                        15,
                                        undefined,
                                        /^7654(0|1|3)|76655|76767\d*$/,
                                        line,
                                    );

                                //SE
                                case '46':
                                    return validateNumberLengthAndValue(
                                        testContext,
                                        value,
                                        15,
                                        undefined,
                                        undefined,
                                        line,
                                    );

                                //SK
                                case '421':
                                    return validateNumberLengthAndValue(
                                        testContext,
                                        value,
                                        15,
                                        undefined,
                                        /^(1650(700|80|906)|190022[0-4]|118)\d*$/,
                                        line,
                                    );

                                //TN - only one number allowed
                                case '216':
                                    return validateNumberLengthAndValue(
                                        testContext,
                                        value,
                                        15,
                                        undefined,
                                        /^(?!(92447237$))\d*$/,
                                        line,
                                    );

                                //TR
                                case '90':
                                    return validateNumberLengthAndValue(
                                        testContext,
                                        value,
                                        15,
                                        undefined,
                                        /^(444|51026[016]|592216|592616[0-4]|850([0-4]|[6-9])|8505([0-2]|[4-9])|85053(0|[2-9])|850531(0|[2-9]))\d*$/,
                                        line,
                                    );

                                //UK
                                case '44': {
                                    const regexNotPossibleNumberUK = new RegExp(
                                        [
                                            '^(5[056]',
                                            '74582',
                                            '70',
                                            '74410',
                                            '84',
                                            '87',
                                            '9',
                                            '70448',
                                            '70525',
                                            '870653',
                                            '871313',
                                            '871572',
                                            '871763',
                                            '871794',
                                            '871817',
                                            '871839',
                                            '872582',
                                            '872663',
                                            '872682',
                                            '3)\\d*$',
                                        ].join('|'),
                                    );

                                    return validateNumberLengthAndValue(
                                        testContext,
                                        value,
                                        15,
                                        undefined,
                                        regexNotPossibleNumberUK,
                                        line,
                                    );
                                }

                                //LT
                                case '370':
                                    return validateNumberLengthAndValue(
                                        testContext,
                                        value,
                                        15,
                                        undefined,
                                        undefined,
                                        line,
                                    );

                                //PH
                                case '63':
                                    return validateNumberLengthAndValue(
                                        testContext,
                                        value,
                                        15,
                                        undefined,
                                        undefined,
                                        line,
                                    );

                                //HK
                                case '852':
                                    return validateNumberLengthAndValue(
                                        testContext,
                                        value,
                                        15,
                                        undefined,
                                        undefined,
                                        line,
                                    );

                                //PT
                                case '351':
                                    return validateNumberLengthAndValue(
                                        testContext,
                                        value,
                                        15,
                                        undefined,
                                        undefined,
                                        line,
                                    );

                                //JP
                                case '81':
                                    return validateNumberLengthAndValue(
                                        testContext,
                                        value,
                                        15,
                                        undefined,
                                        undefined,
                                        // /^(501[0-9]|503[0-9]|504[0-9]|505[0-9]|506[0-9]|507[0-9]|508[0-9]|509[0-9]|52[0-9]|53[0-9]|54[0-9]|55[0-9]|56[0-9]|57[0-9]|58[0-9]|59[0-9]|60[0-9]|61[0-9]|62[0-9]|63[0-9]|64[0-9]|65[0-9]|66[0-9]|67[0-9]|68[0-9]|69[0-9]|70[0-9]|71[0-9]|72[0-9]|73[0-9]|74[0-9]|75[0-9]|76[0-9]|77[0-9]|78[0-9]|79[0-9]|80[0-9]|81[0-9]|82[0-9]|83[0-9]|84[0-9]|85[0-9]|86[0-9]|87[0-9]|88[0-9]|89[0-9]|90[0-9]|91[0-9]|92[0-9]|93[0-9]|94[0-9]|95[0-9]|96[0-9]|97[0-9]|98[0-9]|99[0-9])\d*$/,
                                        line,
                                    );

                                //MX
                                case '52':
                                    return validateNumberLengthAndValue(
                                        testContext,
                                        value,
                                        15,
                                        undefined,
                                        undefined,
                                        // /^(501[0-9]|503[0-9]|504[0-9]|505[0-9]|506[0-9]|507[0-9]|508[0-9]|509[0-9]|52[0-9]|53[0-9]|54[0-9]|55[0-9]|56[0-9]|57[0-9]|58[0-9]|59[0-9]|60[0-9]|61[0-9]|62[0-9]|63[0-9]|64[0-9]|65[0-9]|66[0-9]|67[0-9]|68[0-9]|69[0-9]|70[0-9]|71[0-9]|72[0-9]|73[0-9]|74[0-9]|75[0-9]|76[0-9]|77[0-9]|78[0-9]|79[0-9]|80[0-9]|81[0-9]|82[0-9]|83[0-9]|84[0-9]|85[0-9]|86[0-9]|87[0-9]|88[0-9]|89[0-9]|90[0-9]|91[0-9]|92[0-9]|93[0-9]|94[0-9]|95[0-9]|96[0-9]|97[0-9]|98[0-9]|99[0-9])\d*$/,
                                        line,
                                    );

                                //CH
                                case '41':
                                    return validateNumberLengthAndValue(
                                        testContext,
                                        value,
                                        15,
                                        undefined,
                                        undefined,
                                        // /^(501[0-9]|503[0-9]|504[0-9]|505[0-9]|506[0-9]|507[0-9]|508[0-9]|509[0-9]|52[0-9]|53[0-9]|54[0-9]|55[0-9]|56[0-9]|57[0-9]|58[0-9]|59[0-9]|60[0-9]|61[0-9]|62[0-9]|63[0-9]|64[0-9]|65[0-9]|66[0-9]|67[0-9]|68[0-9]|69[0-9]|70[0-9]|71[0-9]|72[0-9]|73[0-9]|74[0-9]|75[0-9]|76[0-9]|77[0-9]|78[0-9]|79[0-9]|80[0-9]|81[0-9]|82[0-9]|83[0-9]|84[0-9]|85[0-9]|86[0-9]|87[0-9]|88[0-9]|89[0-9]|90[0-9]|91[0-9]|92[0-9]|93[0-9]|94[0-9]|95[0-9]|96[0-9]|97[0-9]|98[0-9]|99[0-9])\d*$/,
                                        line,
                                    );

                                //CO
                                case '57':
                                    return validateNumberLengthAndValue(
                                        testContext,
                                        value,
                                        15,
                                        undefined,
                                        undefined,
                                        // /^(501[0-9]|503[0-9]|504[0-9]|505[0-9]|506[0-9]|507[0-9]|508[0-9]|509[0-9]|52[0-9]|53[0-9]|54[0-9]|55[0-9]|56[0-9]|57[0-9]|58[0-9]|59[0-9]|60[0-9]|61[0-9]|62[0-9]|63[0-9]|64[0-9]|65[0-9]|66[0-9]|67[0-9]|68[0-9]|69[0-9]|70[0-9]|71[0-9]|72[0-9]|73[0-9]|74[0-9]|75[0-9]|76[0-9]|77[0-9]|78[0-9]|79[0-9]|80[0-9]|81[0-9]|82[0-9]|83[0-9]|84[0-9]|85[0-9]|86[0-9]|87[0-9]|88[0-9]|89[0-9]|90[0-9]|91[0-9]|92[0-9]|93[0-9]|94[0-9]|95[0-9]|96[0-9]|97[0-9]|98[0-9]|99[0-9])\d*$/,
                                        line,
                                    );

                                //EE
                                case '372':
                                    return validateNumberLengthAndValue(
                                        testContext,
                                        value,
                                        15,
                                        undefined,
                                        undefined,
                                        line,
                                    );

                                //RO
                                case '40':
                                    return validateNumberLengthAndValue(
                                        testContext,
                                        value,
                                        15,
                                        undefined,
                                        undefined,
                                        line,
                                    );

                                //IT
                                case '39':
                                    return validateNumberLengthAndValue(
                                        testContext,
                                        value,
                                        15,
                                        undefined,
                                        undefined,
                                        line,
                                    );
                                //BG
                                case '359':
                                    return validateNumberLengthAndValue(
                                        testContext,
                                        value,
                                        15,
                                        undefined,
                                        /^359(0|11[0-9]|18[0-9]|150|16[0-6]|0800|0900|0700|000|111)\d*$/,
                                        line,
                                    );
                                //RS
                                case '381':
                                    return validateNumberLengthAndValue(
                                        testContext,
                                        value,
                                        15,
                                        undefined,
                                        /^381(0|1[0-9]{2}|11[2-9]|190|199|118|112|000|111|7\d{2}|9\d{2})\d*$/,
                                        line,
                                    );
                                //KZ
                                case '77':
                                    return validateNumberLengthAndValue(
                                        testContext,
                                        value,
                                        11,
                                        undefined,
                                        /^7(7\d{2}|72\d{2}|73\d{2})\d{6}$/,
                                        line,
                                    );
                                //UA
                                case '380':
                                    return validateNumberLengthAndValue(
                                        testContext,
                                        value,
                                        12,
                                        undefined,
                                        /^380(3\d{2}|[4-6]\d{2}|9[1-9]\d)\d{7}$/,
                                        line,
                                    );
                                default:
                                    return false;
                            }
                        }
                    }
                }
            },
        ),
};

export const PhoneValidationSchema = yup.object(PhoneValidationSchemaFields);

const validateNumberLengthAndValue = (
    testContext: yup.TestContext<Record<string, unknown>>,
    value: string,
    max: number,
    min?: number,
    regexNotPossibleNumbers?: RegExp,
    line?: number,
) => {
    if (regexNotPossibleNumbers) {
        if (regexNotPossibleNumbers.test(value)) {
            return createErrorNotPossibleNumber(testContext, value, line);
        }
    }

    if (value.length > max) {
        return createErrorMaxMinLength(testContext, 'max', max, value, line);
    }

    if (min) {
        if (
            testContext.options?.context?.isAttemptingSubmit &&
            value.length < min
        ) {
            return createErrorMaxMinLength(
                testContext,
                'min',
                min,
                value,
                line,
            );
        }
    }

    return true;
};

const createErrorMaxMinLength = (
    testContext: yup.TestContext<Record<string, unknown>>,
    type: 'max' | 'min',
    limit: number,
    value: string,
    line?: number,
) => {
    return testContext.createError({
        message: testContext.options?.context?.isForm
            ? {
                  key: `errors.phone_number_length_${type}`,
                  values: { [type]: limit },
              }
            : {
                  key: `errors.phone_number_length_${type}_line`,
                  values: { phone: value, line: line, max: limit },
              },
    });
};

const createErrorNotPossibleNumber = (
    testContext: yup.TestContext<Record<string, unknown>>,
    value: string,
    line?: number,
) => {
    return testContext.createError({
        message: testContext.options?.context?.isForm
            ? 'errors.not_possible_number'
            : {
                  key: 'errors.not_possible_number_line',
                  values: { phone: value, line: line },
              },
    });
};

export const PhoneScriptValidationSchema = yup
    .array()
    .of(PhoneValidationSchema);
